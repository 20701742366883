import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";

export function DemoCarousel(props) {
  var items = [
    {
      key: "1",
      name: "Tostada",
      image: require("../../images/slide-Images/Tostada.jpg"),
    },
    {
      key: "2",
      name: "Mole",
      image: require("../../images/slide-Images/Mole_poblano.jpg"),
    },
    {
      key: "3",
      name: "Torta",
      image: require("../../images/slide-Images/Torta.jpg"),
    },
    {
      key: "4",
      name: "Taco de Alambre",
      image: require("../../images/slide-Images/Tacos_De_Alambre.jpg"),
    },
  ];

  return (
    <Carousel navButtonsAlwaysVisible={true}>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  return (
    <Paper style={{ boxShadow: "none" }}>
      <img
        src={props.item.image}
        alt="logo"
        className={"slideImages"}
        loading="eager"
      />
    </Paper>
  );
}
