import { createContext, useReducer, useState } from "react";

//create our context
const NavbarContext = createContext("");

export const NavbarProvider = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <NavbarContext.Provider value={{ isOpen, setOpen }}>
      {children}
    </NavbarContext.Provider>
  );
};

export default NavbarContext;
