import React from "react";
import Elote from "../../images/Elote.svg";
import lemons from "../../images/lemons.svg";

import "../../styles/beinvenidos.css";
function Bienvenidos() {
  return (
    <>
    <div className="rectangle">
      <div className="heading">
        {/* icon */}
        <img
          src={Elote}
          alt="logo"
          className={"EloteLogo"}
        />
        Bienvenidos
        {/* icon */}
        <img src={lemons} alt="logo" className={"LemonsLogo"} />
        {/* text */}
        </div>
        <div className="biotextOutterBox">
          <div class='bioText'> El Jarochito is a family owned Mexican food truck, We are located on the corner of 111th street and 43rd Avenue in Corona Queens! We offer a wide array of authentic delicious food. From Tacos, Quesadillas, Nachos Hamburgers, Salchipapas, and Tortas to name a few. Stop by our Food truck Today! 
          </div>
          <br></br>
          <div className="bioText"> El Jarochito es un camión de comida mexicana de propiedad familiar. Estamos ubicados en la esquina de la calle 111 y la Avenida 43 en Corona Queens! Ofrecemos una amplia variedad de auténtica comida deliciosa. Desde Tacos, Quesadillas, Nachos Hamburgesas, Salchipapas, y Tortas para nombrar unos pocos. ¡Pase por nuestro camión de comida hoy!
          </div>
      </div>
      {/* biz bio */}
    </div>

      </>
  );
}

export default Bienvenidos;
