import React from "react";
import "../../styles/location.css";
import sombrero from "../../images/Sombrero.svg";

import facebook from "../../images/facebook.svg";
import instagram from "../../images/instagram.svg";
import grubhub from "../../images/grubhub.svg";
import google from "../../images/google.svg";

function Location() {
  return (
    <>
      <div className="locationBox" id="LocationHome">
        <div id="col-1">
          <img src={sombrero} alt="sombrero" className={"sombrero"} />

          <div className="locationTxt">
            El jarochito is located on the corner of 43rd ave and 111th street
            corona ny, 11368
            <br />
            <br />
            follow us on social media
          </div>
          <div className="socialMediaIcons">
            <a
              href="https://www.facebook.com/profile.php?id=100091829792104"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="facebook" className={"facebook"} />
            </a>
            <a
              href="https://www.instagram.com/eljarochitocorona/?fbclid=IwAR0Q8WMfGTUKK_UU9CL5MeoQgoc7eFmNaAVXfM4c4LrL4WQmMTZWptO1i6c"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="instagram" className={"instagram"} />
            </a>

            <a
              href="https://www.grubhub.com/restaurant/el-jarochito-mexican-food-truck-43-14-111th-st-corona/5822504?proof=true"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img src={grubhub} alt="grubhub" className={"grubhub"} />
            </a>
            <a
              href="https://www.google.com/maps/place/El+Jarochito+Mexican+Food+Truck/@40.7494746,-73.8548974,17z/data=!3m1!4b1!4m6!3m5!1s0x89c25fd500648083:0x2905c61bc0036678!8m2!3d40.7494746!4d-73.8548974!16s%2Fg%2F11sk6r3sgp?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img src={google} alt="google" className={"google"} />
            </a>
          </div>
        </div>
        <div id="col-2">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d377.8218972632024!2d-73.85486789958078!3d40.749372465145456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25fd500648083%3A0x2905c61bc0036678!2sEl%20Jarochito%20Mexican%20Food%20Truck!5e0!3m2!1sen!2sus!4v1699301936223!5m2!1sen!2sus"
            style={{ border: 0, height: "100%", width: "100%" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="Google maps percise location"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default Location;
