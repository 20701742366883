import React, { useEffect } from "react";
import Bienvenidos from "../bienvenidos/Bienvenidos";
import Location from "../location/Location";
import chicharon from "../../images/Chicharron_Preparado.jpg";
import { DemoCarousel } from "../carousel/DemoCarousel";
import Ourfood from "../ourfood/Ourfood";
function HomeScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <body>
        <DemoCarousel />
        {/* Bienvenidos */}
        <Bienvenidos />
        <div>
          <img src={chicharon} alt="logo" className={"chicharon"} />
        </div>
        <Ourfood />
        <Location id="location" />
      </body>
    </>
  );
}

export default HomeScreen;
