import React from "react";
import "../../styles/ourfood.css";
import chicken from "../../images/chicken.svg";
import cow from "../../images/cow.svg";
import { Link } from "react-router-dom";

function Ourfood() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="ourFoodrectangle">
        <div className="heading-OurFood">
          {/* icon */}
          <img src={cow} alt="cow" className={"cow"} />
          <div className="textAndButton">
            {" "}
            Our Food
            <div className="smallerTxt">See What we're serving</div>
            <button onClick={scrollToTop} className="btn">
              <Link to="/menu" id="btnLink">
                View Menu
              </Link>
            </button>
          </div>

          {/* icon */}
          <img src={chicken} alt="chicken" className={"chicken"} />
          {/* text */}
        </div>
      </div>
    </>
  );
}

export default Ourfood;
