import React from "react";
import "../../styles/PageNotFound.css";

function PageNotFound() {
  return (
    <>
      <div className="PageNotFoundContainer">
        <div>404</div>
        <div>oops....Page not found</div>
        <div>Please Try Another page</div>
      </div>
    </>
  );
}

export default PageNotFound;
