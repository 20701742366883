import "./App.css";
import NavBar from "./components/navbar/NavBar";
import "../src/styles/images.css";
import Menu from "./components/Pages/Menu";
import Home from "./components/Pages/Home";
import Location from "./components/location/Location";
import OrderNow from "./components/Pages/OrderNow";
import PageNotFound from "./components/Pages/PageNotFound";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { NavbarProvider } from "./context/NavbarContext";

function App() {
  return (
    <NavbarProvider>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/notfound" element={<PageNotFound />} />
          <Route path="/ordernow" element={<OrderNow />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/location" element={<Location />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </NavbarProvider>
  );
}

export default App;
