import React from "react";
import "../../styles/OrderNow.css";

import grubhubLogo from "../../images/grubhub-logo.svg";
import ubereatsLogo from "../../images/uber-eats.svg";
import doordash from "../../images/doordash-logo.svg";

function OrderNow() {
  return (
    <>
      <div className={"orderNowContainer"}>
        <div className={"mainDiv"}>Our online store is Coming soon!...</div>
        <div className={"phoneDiv"}>
          Orders can be made for pick up by telephone +1(347) 617-7331
        </div>
        <div className={"phoneDiv"}>or by our online stores (click below)</div>
        <div className={"onlineStoresDiv"}>
          <a
            href="https://www.grubhub.com/restaurant/el-jarochito-mexican-food-truck-43-14-111th-st-corona/5822504?proof=true"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={grubhubLogo}
              alt="grubhub-logo"
              className={"grubhub-logo"}
            />
          </a>
          <div>
            <a
              href="https://www.ubereats.com/store/el-jarochito-mexican-food-truck/umyhkMSqW82dSeCaOisX1w?utm_source=menu-maker"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={ubereatsLogo}
                alt="ubereats-logo"
                className={"ubereats-logo"}
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.doordash.com/store/el-jarochito-mexican-food-truck-queens-25225723/24577149/?preview=1"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={doordash}
                alt="doordash-logo"
                className={"doordash-logo"}
              />
            </a>
          </div>
        </div>
      </div>
      ;
    </>
  );
}

export default OrderNow;
