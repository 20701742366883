import React, { useState, useContext } from "react";
import elJarochitoLogo from "../../images/eljLogo.png";
import "../../styles/navbar.css";
import Hamburger from "./Hamburger";
import MobileNavBar from "../navbar/MobileNavBar";
import { Link } from "react-router-dom";
import NavbarContext from "../../context/NavbarContext";
function NavBar() {
  // hamburger state
  // const [hamBurgerOpen, setHamBurgerOpen] = useState(false);

  const toggleHamburger = () => {
    // setHamBurgerOpen(!hamBurgerOpen);
    setOpen(!isOpen);
  };
  const { isOpen, setOpen } = useContext(NavbarContext);

  return (
    <>
      <div className="box">
        {/* logo */}
        <img src={elJarochitoLogo} alt="logo" className={"imgLogo"} />
      </div>
      <div className="HamAndNav">
        <nav className="navbar">
          <div className="navbar-links">
            <ul>
              <li style={{ fontSize: "20px", paddingRight: "60px" }}>
                <Link to="/" className="link">
                  Home
                </Link>
              </li>
              <li style={{ fontSize: "20px", paddingRight: "60px" }}>
                {" "}
                <Link to="/menu" className="link">
                  Menu{" "}
                </Link>
              </li>
              <li style={{ fontSize: "20px", paddingRight: "60px" }}>
                <Link to="/ordernow" className="link">
                  Order Now
                </Link>
              </li>
              <li style={{ fontSize: "20px", paddingRight: "60px" }}>
                <Link to="/location">Location</Link>
              </li>
            </ul>
          </div>
        </nav>
        <div className="hamburger" onClick={toggleHamburger}>
          <Hamburger isOpen={isOpen} />
        </div>
      </div>
      {isOpen ? <MobileNavBar /> : null}
    </>
  );
}

export default NavBar;
