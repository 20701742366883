import React from "react";

function Hamburger({ isOpen }) {
  return (
    <>
      <div>
        <div className="burger burger1" />
        <div className="burger burger2" />
        <div className="burger burger3" />
      </div>

      <style jsx>
        {`
          .hamburger {
            width: 2rem;
            height: 2rem;
            display: flex;
            justify-content: space-around;
            flex-flow: column nowrap;
            z-index: 10;
          }

          .burger {
            width: 2rem;
            height: 0.2rem;
            border-radius: 10px;
            background-color: black;
            transform-origin: 8px;
            transition: all 0.3s linear;
            margin-top: 2px;
          }

          .burger1 {
            transform: ${isOpen ? "rotate(45deg)" : "rotate(0)"};
          }
          .burger2 {
            transform: ${isOpen ? "translateX(100%)" : "translateX(0)"};
            opacity: ${isOpen ? 0 : 1};
          }
          .burger3 {
            transform: ${isOpen ? "rotate(-45deg)" : "rotate(0)"};
          }
        `}
      </style>
    </>
  );
}

export default Hamburger;
