import React from "react";
import "../../styles/menu.css";
function Menu() {
  return (
    <>
      <div className={"Container"}>
        {/* two columns */}
        <div className={"col1"}>
          {/* tacos */}
          <div className={"MenuBlockContainer"}>
            <div className={"ItemHeadingTitle"}>Tacos</div>
            <div className={"ItemDescription"}>
              Arranged on a soft corn Tortilla with your choice of meat. Served
              with onions and cilantro.
            </div>

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Bistec</div>
              <div className={"price"}>$4</div>
            </div>
            <div className={"VariationDescription"}>Steak</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Pollo</div>
              <div className={"price"}>$4</div>
            </div>
            <div className={"VariationDescription"}>Chicken</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Oreja</div>
              <div className={"price"}>$4</div>
            </div>
            <div className={"VariationDescription"}>Pork ear</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Al Pastor</div>
              <div className={"price"}>$4</div>
            </div>
            <div className={"VariationDescription"}>
              Shepered style seasoned meat
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Enchilada</div>
              <div className={"price"}>$4</div>
            </div>
            <div className={"VariationDescription"}>
              Pork in Savory spicy sauce
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Carnitas</div>
              <div className={"price"}>$4</div>
            </div>
            <div className={"VariationDescription"}>Pork</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Cesina</div>
              <div className={"price"}>$5</div>
            </div>
            <div className={"VariationDescription"}>Skirt Steak</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Chorizo</div>
              <div className={"price"}>$5</div>
            </div>
            <div className={"VariationDescription"}>Pork Sausage</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Campechano</div>
              <div className={"price"}>$5</div>
            </div>
            <div className={"VariationDescription"}>Steak and Pork</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Mixto</div>
              <div className={"price"}>$5</div>
            </div>
            <div className={"VariationDescription"}>Mixed Meat</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Lengua</div>
              <div className={"price"}>$6</div>
            </div>
            <div className={"VariationDescription"}>Beef tongue</div>
            <hr className={"DottedLine"} id={"hrlineBottomMargin"} />
          </div>
          {/* Birria */}
          <div className={"MenuBlockContainer"}>
            <div className={"ItemHeadingTitle"}>Birria</div>
            <div className={"ItemDescription"}>
              Arranged on a corn tortilla with chopped Birria, onion, and
              cilantro.
            </div>

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Taco de Birria</div>
              <div className={"price"}>$4</div>
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Mulita</div>
              <div className={"price"}>$5</div>
            </div>
            <div className={"VariationDescription"}>
              Corn tortilla stuffed with chopped Birria, melted mozzarella,
              onion, cilantro, and grated cheese.
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Tostada de Birria</div>
              <div className={"price"}>$5</div>
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Torta de Birria</div>
              <div className={"price"}>$10</div>
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Quesadilla de Birria</div>
              <div className={"price"}>$10</div>
            </div>
            <div className={"VariationDescription"}>
              Large Corn tortilla stuffed with chopped birria, melted
              mozzarella, onion, cilantro.
            </div>
            <hr className={"DottedLine"} id={"hrlineBottomMargin"} />
          </div>
          {/* Combos */}
          <div className={"MenuBlockContainer"}>
            <div className={"ItemHeadingTitle"}>Combos</div>
            <div className={"ItemDescription"}>
              Mixed Arrangement platter comes with grilled chicken breast, spicy
              pork, breaded chicken breast, Cecina, Chorizo, grilled cheese,
              cooked onions, sausage, rice, beans and tortillas
            </div>

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Bandeja Mixto Jarochito</div>
              <div className={"price"}>$35</div>
            </div>
            <hr className={"DottedLine"} id={"hrlineBottomMargin"} />
          </div>
          {/* Saldas */}
          <div className={"MenuBlockContainer"}>
            <div className={"ItemHeadingTitle"}>Saldas</div>
            <div className={"ItemDescription"}>
              Made with tomatoes, onions, lettuce, and avocado
            </div>

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Nopal</div>
              <div className={"price"}>$8</div>
            </div>
            <div className={"VariationDescription"}>Cactus</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Pechuga de Pollo</div>
              <div className={"price"}>$10</div>
            </div>
            <div className={"VariationDescription"}>
              Grilled Chicken breast Salad
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Milanesa de Pollo</div>
              <div className={"price"}>$10</div>
            </div>
            <div className={"VariationDescription"}>
              Breaded seasoned chicken salad
            </div>
            <hr className={"DottedLine"} id={"hrlineBottomMargin"} />

            {/* Tacos Placeros */}
          </div>
          {/* burritos */}
          <div className={"MenuBlockContainer"}>
            <div className={"ItemHeadingTitle"}> Burritos</div>
            <div className={"ItemDescription"}>
              Arranged on a large flour tortilla with your choice of meat,
              beans, rice, mozzarella cheese, lettuce, tomato, and topped with
              Mexican crema.
            </div>

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Bistec</div>
              <div className={"price"}>$12</div>
            </div>
            <div className={"VariationDescription"}>Steak</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Al Pastor</div>
              <div className={"price"}>$12</div>
            </div>
            <div className={"VariationDescription"}>
              Shepered Style seasoned meat
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Pollo</div>
              <div className={"price"}>$12</div>
            </div>
            <div className={"VariationDescription"}>Chicken</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Enchilada</div>
              <div className={"price"}>$12</div>
            </div>
            <div className={"VariationDescription"}>
              Pork in savory spicy enchilada sauce
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Carnitas</div>
              <div className={"price"}>$12</div>
            </div>
            <div className={"VariationDescription"}>Pork</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Chorizo</div>
              <div className={"price"}>$15</div>
            </div>
            <div className={"VariationDescription"}>Pork Sausage</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Lengua</div>
              <div className={"price"}>$15</div>
            </div>
            <div className={"VariationDescription"}>Beef Tongue</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Cesina</div>
              <div className={"price"}>$15</div>
            </div>
            <div className={"VariationDescription"}>Skirt Steak</div>
            <hr className={"DottedLine"} id={"hrlineBottomMargin"} />
          </div>
          {/* Burritos de Alambre  */}
          <div className={"MenuBlockContainer"}>
            <div className={"ItemHeadingTitle"}> Burritos de Alambre</div>
            <div className={"ItemDescription"}>
              Arranged on a large flour tortilla, with rice, beans, peppers,
              cheese, onions and cilantro. Served with a salad on the side.
            </div>

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Pollo</div>
              <div className={"price"}>$15</div>
            </div>
            <div className={"VariationDescription"}>Chicken</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Bistec</div>
              <div className={"price"}>$15</div>
            </div>
            <div className={"VariationDescription"}>Steak</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Camaron</div>
              <div className={"price"}>$15</div>
            </div>
            <div className={"VariationDescription"}>Shrimp</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Carne Enchilada</div>
              <div className={"price"}>$15</div>
            </div>
            <div className={"VariationDescription"}>
              Pork in savory spicy enchilada sauce
            </div>
            <hr className={"DottedLine"} id={"hrlineBottomMargin"} />
          </div>

          {/* Vegetarian */}
          <div className={"MenuBlockContainer"}>
            <div className={"ItemHeadingTitle"}> Vegeterian</div>

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Cebollitas</div>
              <div className={"price"}>$4</div>
            </div>
            <div className={"VariationDescription"}>Grilled Spring Onions</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Papitas</div>
              <div className={"price"}>$4</div>
            </div>
            <div className={"VariationDescription"}>Fries</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Nopales con Cebollitas</div>
              <div className={"price"}>$6</div>
            </div>
            <div className={"VariationDescription"}>
              Grilled Cactus, and spring onions
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}> Enchiladas, Mole y Verdes</div>
              <div className={"price"}>$13</div>
            </div>
            <div className={"VariationDescription"}>
              Savory spicy chile sauce, with mole and greens
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>
                {" "}
                Enchiladas, con Arroz, Frijoles, Mole y Verdes
              </div>
              <div className={"price"}>$15</div>
            </div>
            <div className={"VariationDescription"}>
              Savory spicy chile sauce, with rice, beans mole and greens
            </div>
            <hr className={"DottedLine"} id={"hrlineBottomMargin"} />
          </div>

          {/* Entrees */}
          <div className={"MenuBlockContainer"}>
            <div className={"ItemHeadingTitle"}> Entrees</div>
            <div className={"ItemDescription"}>
              Served with rice, beans and cactus
            </div>

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Mixed Rice</div>
              <div className={"price"}>$12</div>
            </div>
            <div className={"VariationDescription"}>
              Served with rice, onions, and your choice of steak, pernil or
              chicken
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Guisado</div>
              <div className={"price"}>$14</div>
            </div>
            <div className={"VariationDescription"}>
              Stew, with your choice of meat
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Huevos al Gusto</div>
              <div className={"price"}>$14</div>
            </div>
            <div className={"VariationDescription"}>any style eggs</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Mole Poblano</div>
              <div className={"price"}>$14</div>
            </div>
            <div className={"VariationDescription"}>
              Chicken covered in mole sauce
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Carne Asada</div>
              <div className={"price"}>$15</div>
            </div>
            <div className={"VariationDescription"}>Grilled Steak</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Pechuga Asada</div>
              <div className={"price"}>$15</div>
            </div>
            <div className={"VariationDescription"}>Grilled Chicken</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Cecina</div>
              <div className={"price"}>$17</div>
            </div>
            <div className={"VariationDescription"}>Cecina Steak</div>
            <hr className={"DottedLine"} id={"hrlineBottomMargin"} />
          </div>

          {/* Entrees */}
          <div className={"MenuBlockContainer"}>
            <div className={"ItemHeadingTitle"}> Drinks</div>

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Water</div>
              <div className={"price"}>$1.50</div>
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Canned Soda</div>
              <div className={"price"}>$2</div>
            </div>
            <div className={"VariationDescription"}>
              Ginger Ale, Coke, Pepsi, Fanta
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Jaritos - Mexican Sodas</div>
              <div className={"price"}>$3</div>
            </div>
            <div className={"VariationDescription"}>
              Tamarind, Mandarin, Pineapple, Lemon
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Gatorade</div>
              <div className={"price"}>$3</div>
            </div>
            <div className={"VariationDescription"}>
              Fruit Punch, Forrest Glacier, Lemon
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Aguas Frescas</div>
              <div className={"price"}>$5/10</div>
            </div>
            <div className={"VariationDescription"}>
              Tamarindo, Horchata, Coco, Lemonande, Jamaica
            </div>
            <hr className={"DottedLine"} id={"hrlineBottomMargin"} />
          </div>
        </div>

        {/* second column  */}
        <div className={"col2"}>
          {/* tacos placeros */}
          <div className={"MenuBlockContainer"} id={"secondCol"}>
            <div className={"ItemHeadingTitle"} id={"TplacerosText"}>
              Tacos Placeros
            </div>
            <div className={"ItemDescription"}>
              Large tacos with your choice of meat
            </div>
            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Camaron</div>
              <div className={"price"}>$6</div>
            </div>
            <div className={"VariationDescription"}>Shrimp</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Huevo</div>
              <div className={"price"}>$6</div>
            </div>
            <div className={"VariationDescription"}>Egg Taco</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Camaron</div>
              <div className={"price"}>$6</div>
            </div>
            <div className={"VariationDescription"}>Shrimp</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Chile Relleno</div>
              <div className={"price"}>$6</div>
            </div>
            <div className={"VariationDescription"}>
              Stuffed Poblano Pepper with mexican cheese
            </div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Milanesa de Pollo</div>
              <div className={"price"}>$6</div>
            </div>
            <div className={"VariationDescription"}>Breaded chicken Taco</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Con Rajas</div>
              <div className={"price"}>$7</div>
            </div>
            <hr className={"DottedLine"} id={"hrlineBottomMargin"} />

            {/* end of Tacos Placeros */}
          </div>

          {/* Tacos De Alambre */}
          <div className={"MenuBlockContainer"} id={"secondCol"}>
            <div className={"ItemHeadingTitle"}>Tacos De Alambre</div>
            <div className={"ItemDescription"}>
              Arranged on soft corn tortilla with peppers, cheese and onions.
            </div>
            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Camaron</div>
              <div className={"price"}>$6</div>
            </div>
            <div className={"VariationDescription"}>Shrimp</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Bistec</div>
              <div className={"price"}>$6</div>
            </div>
            <div className={"VariationDescription"}>Steak</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Pollo</div>
              <div className={"price"}>$6</div>
            </div>
            <div className={"VariationDescription"}>Chicken</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Carne Enchilada</div>
              <div className={"price"}>$6</div>
            </div>
            <div className={"VariationDescription"}>Chile Marinated Beef</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />
          </div>

          {/* quesadillas */}
          <div className={"MenuBlockContainer"} id={"secondCol"}>
            <div className={"ItemHeadingTitle"}>Quesadillas</div>
            <div className={"ItemDescription"}>
              Arranged on flour tortilla with your choice of meat, lettuce and
              Mexican crema.
            </div>
            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Tinga</div>
              <div className={"price"}>$8</div>
            </div>
            <div className={"VariationDescription"}>
              Shreded chicken cooked in flavorful tomato adobo sauce.
            </div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Bistec</div>
              <div className={"price"}>$8</div>
            </div>
            <div className={"VariationDescription"}>Steak Quesadilla</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Al Pastor</div>
              <div className={"price"}>$8</div>
            </div>
            <div className={"VariationDescription"}>
              Shepered style seasoned pork quesadilla
            </div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Pollo</div>
              <div className={"price"}>$8</div>
            </div>
            <div className={"VariationDescription"}>Chicken Quesadilla</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Enchilada</div>
              <div className={"price"}>$8</div>
            </div>
            <div className={"VariationDescription"}>
              Pork in savory spicy sauce
            </div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Carnitas</div>
              <div className={"price"}>$8</div>
            </div>
            <div className={"VariationDescription"}>Pork</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Hongo</div>
              <div className={"price"}>$10</div>
            </div>
            <div className={"VariationDescription"}>Mushroom</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Cesina</div>
              <div className={"price"}>$10</div>
            </div>
            <div className={"VariationDescription"}>Skirt Steak</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Chorizo</div>
              <div className={"price"}>$10</div>
            </div>
            <div className={"VariationDescription"}>Pork Sausage</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Chorizo</div>
              <div className={"price"}>$10</div>
            </div>
            <div className={"VariationDescription"}>Pork Sausage</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Campechano</div>
              <div className={"price"}>$10</div>
            </div>
            <div className={"VariationDescription"}>Pork and Steak</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Huitlacoche</div>
              <div className={"price"}>$10</div>
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Lengua</div>
              <div className={"price"}>$12</div>
            </div>
            <div className={"VariationDescription"}>Beef tongue </div>
            <hr className={"DottedLine"} id={"hrlineBottomMargin"} />
            {/* end of Quesadillas */}
          </div>

          {/* nachos */}
          <div className={"MenuBlockContainer"} id={"secondCol"}>
            <div className={"ItemHeadingTitle"}>Nachos</div>
            <div className={"ItemDescription"}>
              Tortilla chips, with cheese ,tomatoes, mexican creama, grated
              cheese, lettuce, black bean, and optional choice of meat :Steak,
              Carnitas, savory enchilada, Al Pastor, or Chicken.
            </div>
            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Regular</div>
              <div className={"price"}>$13</div>
            </div>
            <div className={"VariationDescription"}>non-meat nachos</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Texanos</div>
              <div className={"price"}>$15</div>
            </div>
            <div className={"VariationDescription"}>
              {" "}
              with your choice of meat
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Mixto</div>
              <div className={"price"}>$15</div>
            </div>
            <div className={"VariationDescription"}>
              choose two diffrent types of meat from the above item description
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Lengua</div>
              <div className={"price"}>$19</div>
            </div>
            <div className={"VariationDescription"}>Beef Tongue</div>
            {/* menu variation */}
            <hr className={"DottedLine"} id={"hrlineBottomMargin"} />
            {/* end of Nachos */}
          </div>

          {/* Quesadillas de Alambre */}
          <div className={"MenuBlockContainer"} id={"secondCol"}>
            <div className={"ItemHeadingTitle"}>Quesadillas de Alambre</div>
            <div className={"ItemDescription"}>
              Arranged on flour tortilla with peppers, onions, and cheese.
            </div>
            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Pollo</div>
              <div className={"price"}>$12</div>
            </div>
            <div className={"VariationDescription"}>Chicken</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Bistec</div>
              <div className={"price"}>$12</div>
            </div>
            <div className={"VariationDescription"}> Steak</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Camaron</div>
              <div className={"price"}>$12</div>
            </div>
            <div className={"VariationDescription"}>Shrimp</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Carne Enchilada</div>
              <div className={"price"}>$12</div>
            </div>
            <div className={"VariationDescription"}>Chile Marinated Beef</div>
            {/* menu variation */}
            <hr className={"DottedLine"} id={"hrlineBottomMargin"} />
          </div>

          {/* Others */}
          <div className={"MenuBlockContainer"} id={"secondCol"}>
            <div className={"ItemHeadingTitle"}>Others</div>
            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Palomitas</div>
              <div className={"price"}>$2</div>
            </div>
            <div className={"VariationDescription"}>Popcorn</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Hot Dog</div>
              <div className={"price"}>$4</div>
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Camaron</div>
              <div className={"price"}>$12</div>
            </div>
            <div className={"VariationDescription"}>Shrimp</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Tostadas</div>
              <div className={"price"}>$5/6</div>
            </div>
            <div className={"VariationDescription"}>
              Arranged on a cruncy tortilla, with mexican cheese, creama and red
              tomato. Your choice of meat can be added for ($1)
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Esquites</div>
              <div className={"price"}>$5/10</div>
            </div>
            <div className={"VariationDescription"}>
              corn mixed with creamy mayonesa (mayonnaise), freshly crumbled
              cotija cheese or queso fresco, lime juice, and chili powder in a
              cup. (Small $5, Large $10)
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Gorditas</div>
              <div className={"price"}>$7</div>
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Sopes</div>
              <div className={"price"}>$7/8</div>
            </div>
            <div className={"VariationDescription"}>
              Arranged on thick corn tortilla cake with your choice of meat,
              crema, onions, lettuce, refried beans. Optional meat can be added
              for ($1)
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Chicharon Preparado</div>
              <div className={"price"}>$8</div>
            </div>
            <div className={"VariationDescription"}>Prepared Pork Rinds</div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Pollo Papas</div>
              <div className={"price"}>$9</div>
            </div>
            <div className={"VariationDescription"}>
              Fried Chicken and Fries
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Salchipapas</div>
              <div className={"price"}>$10</div>
            </div>
            <div className={"VariationDescription"}>
              cut up sausage and fries mix
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Hamburger Deluxe</div>
              <div className={"price"}>$10</div>
            </div>
            <div className={"VariationDescription"}>
              American Cheese Burger with, lettuce tomato, and fries
            </div>
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Flautas</div>
              <div className={"price"}>$12</div>
            </div>
            <hr className={"DottedLine"} id={"hrlineBottomMargin"} />
          </div>

          {/* Mexican Sandwiches */}
          <div className={"MenuBlockContainer"} id={"secondCol"}>
            <div className={"ItemHeadingTitle"}>Meixcan Sandwiches</div>
            <div className={"ItemDescription"}>
              Mexican Tortas and Cemitas come with lettuce, tomato, avocado,
              black beans mashed ,melted cheese and mayonnaise, and with your
              choice of meat (Al Pastor, Ham, Enchilada, Pollo Asado, Milanesa
              de pollo
            </div>
            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Jamon</div>
              <div className={"price"}>$9</div>
            </div>
            <div className={"VariationDescription"}>Ham Sandwich</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Tortas</div>
              <div className={"price"}>$10</div>
            </div>
            <div className={"VariationDescription"}>
              {" "}
              Served on a roll, with your choice of meat
            </div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Cemitas</div>
              <div className={"price"}>$10</div>
            </div>
            <div className={"VariationDescription"}>
              Served on a seasame seed bun, with your choice of meat
            </div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Campechano</div>
              <div className={"price"}>$12</div>
            </div>
            <div className={"VariationDescription"}>
              Pork and Steak sandwich
            </div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Lengua</div>
              <div className={"price"}>$12</div>
            </div>
            <div className={"VariationDescription"}>Beef Tongue sandwich</div>
            {/* menu variation */}
            <hr className={"DottedLine"} />

            <div className={"ItemPriceAndBox"}>
              <div className={"Item"}>Cubana</div>
              <div className={"price"}>$14</div>
            </div>
            <div className={"VariationDescription"}>
              Steak, Sausage, and eggs
            </div>
            <hr className={"DottedLine"} id={"hrlineBottomMargin"} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Menu;
