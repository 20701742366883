import React, { useState, useContext } from "react";
import "../../styles/mobileNavbar.css";
import { Link } from "react-router-dom";
import NavbarContext from "../../context/NavbarContext";
function MobileNavBar() {
  const { isOpen, setOpen } = useContext(NavbarContext);

  const toggleHamNavBars = () => {
    setOpen(!isOpen);
  };

  return (
    <>
      <div className="mobileNavBox">
        <nav>
          <ul className="mobileUL">
            <li className={"liStlye"}>
              <Link to="/" className="link" onClick={toggleHamNavBars}>
                Home
              </Link>
            </li>
            <li className={"liStlye"}>
              <Link to="/menu" className="link" onClick={toggleHamNavBars}>
                Menu{" "}
              </Link>
            </li>
            <li className={"liStlye"}>
              <Link to="/ordernow" className="link" onClick={toggleHamNavBars}>
                Order Now
              </Link>
            </li>
            <li className={"liStlye"}>
              <Link to="/location" onClick={toggleHamNavBars}>
                Location
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default MobileNavBar;
